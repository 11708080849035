



















export default {
  props: {
    blok: { type: Object, required: true },
  },
};
